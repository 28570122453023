! function (e, t) {
    var n = t(e, e.document);
    e.lazySizes = n, "object" == typeof module && module.exports && (module.exports = n)
}(window, function (e, t) {
    "use strict";
    if (t.getElementsByClassName) {
        var n, a = t.documentElement,
            i = e.HTMLPictureElement && "sizes" in t.createElement("img"),
            r = "addEventListener",
            s = "getAttribute",
            o = e[r],
            l = e.setTimeout,
            c = e.requestAnimationFrame || l,
            d = /^picture$/i,
            u = ["load", "error", "lazyincluded", "_lazyloaded"],
            f = {},
            m = Array.prototype.forEach,
            z = function (e, t) {
                return f[t] || (f[t] = new RegExp("(\\s|^)" + t + "(\\s|$)")), f[t].test(e[s]("class") || "") && f[
                    t]
            },
            g = function (e, t) {
                z(e, t) || e.setAttribute("class", (e[s]("class") || "").trim() + " " + t)
            },
            v = function (e, t) {
                var n;
                (n = z(e, t)) && e.setAttribute("class", (e[s]("class") || "").replace(n, " "))
            },
            y = function (e, t, n) {
                var a = n ? r : "removeEventListener";
                n && y(e, t), u.forEach(function (n) {
                    e[a](n, t)
                })
            },
            h = function (e, n, a, i, r) {
                var s = t.createEvent("CustomEvent");
                return s.initCustomEvent(n, !i, !r, a || {}), e.dispatchEvent(s), s
            },
            p = function (t, a) {
                var r;
                !i && (r = e.picturefill || n.pf) ? r({
                    reevaluate: !0,
                    elements: [t]
                }) : a && a.src && (t.src = a.src)
            },
            C = function (e, t) {
                return (getComputedStyle(e, null) || {})[t]
            },
            b = function (e, t, a) {
                for (a = a || e.offsetWidth; a < n.minSize && t && !e._lazysizesWidth;) a = t.offsetWidth, t = t.parentNode;
                return a
            },
            A = function (t) {
                var n, a = 0,
                    i = e.Date,
                    r = function () {
                        n = !1, a = i.now(), t()
                    },
                    s = function () {
                        l(r)
                    },
                    o = function () {
                        c(s)
                    };
                return function () {
                    if (!n) {
                        var e = 125 - (i.now() - a);
                        n = !0, 6 > e && (e = 6), l(o, e)
                    }
                }
            },
            E = function () {
                var i, u, f, b, E, M, w, _, x, W, B, S, L, R, D, T = /^img$/i,
                    k = /^iframe$/i,
                    F = "onscroll" in e && !/glebot/.test(navigator.userAgent),
                    O = 0,
                    P = 0,
                    $ = 0,
                    H = 0,
                    I = function (e) {
                        $--, e && e.target && y(e.target, I), (!e || 0 > $ || !e.target) && ($ = 0)
                    },
                    j = function (e, n) {
                        var i, r = e,
                            s = "hidden" == C(t.body, "visibility") || "hidden" != C(e, "visibility");
                        for (x -= n, S += n, W -= n, B += n; s && (r = r.offsetParent) && r != t.body && r != a;) s =
                            (C(r, "opacity") || 1) > 0, s && "visible" != C(r, "overflow") && (i = r.getBoundingClientRect(),
                                s = B > i.left && W < i.right && S > i.top - 1 && x < i.bottom + 1);
                        return s
                    },
                    q = function () {
                        var e, t, r, o, l, c, d, m, z;
                        if ((E = n.loadMode) && 8 > $ && (e = i.length)) {
                            t = 0, H++, null == R && ("expand" in n || (n.expand = a.clientHeight > 600 ? a.clientWidth >
                                    600 ? 550 : 410 : 359), L = n.expand, R = L * n.expFactor), R > P && 1 > $ && H >
                                3 && E > 2 ? (P = R, H = 0) : P = E > 1 && H > 2 && 6 > $ ? L : O;
                            for (; e > t; t++)
                                if (i[t] && !i[t]._lazyRace)
                                    if (F)
                                        if ((m = i[t][s]("data-expand")) && (c = 1 * m) || (c = P), z !== c && (w =
                                                innerWidth + c * D, _ = innerHeight + c, d = -1 * c, z = c), r = i[
                                                t].getBoundingClientRect(), (S = r.bottom) >= d && (x = r.top) <= _ &&
                                            (B = r.right) >= d * D && (W = r.left) <= w && (S || B || W || x) && (f &&
                                                3 > $ && !m && (3 > E || 4 > H) || j(i[t], c))) {
                                            if (X(i[t]), l = !0, $ > 9) break
                                        } else !l && f && !o && 4 > $ && 4 > H && E > 2 && (u[0] || n.preloadAfterLoad) &&
                                            (u[0] || !m && (S || B || W || x || "auto" != i[t][s](n.sizesAttr))) &&
                                            (o = u[0] || i[t]);
                            else X(i[t]);
                            o && !l && X(o)
                        }
                    },
                    G = A(q),
                    J = function (e) {
                        g(e.target, n.loadedClass), v(e.target, n.loadingClass), y(e.target, K)
                    },
                    K = function (e) {
                        e = {
                            target: e.target
                        }, c(function () {
                            J(e)
                        })
                    },
                    Q = function (e, t) {
                        try {
                            e.contentWindow.location.replace(t)
                        } catch (n) {
                            e.src = t
                        }
                    },
                    U = function (e) {
                        var t, a, i = e[s](n.srcsetAttr);
                        (t = n.customMedia[e[s]("data-media") || e[s]("media")]) && e.setAttribute("media", t), i &&
                            e.setAttribute("srcset", i), t && (a = e.parentNode, a.insertBefore(e.cloneNode(), e),
                                a.removeChild(e))
                    },
                    V = function () {
                        var e, t = [],
                            n = function () {
                                for (; t.length;) t.shift()();
                                e = !1
                            },
                            a = function (a) {
                                t.push(a), e || (e = !0, c(n))
                            };
                        return {
                            add: a,
                            run: n
                        }
                    }(),
                    X = function (e) {
                        var t, a, i, r, o, u, C, A = T.test(e.nodeName),
                            E = A && (e[s](n.sizesAttr) || e[s]("sizes")),
                            M = "auto" == E;
                        (!M && f || !A || !e.src && !e.srcset || e.complete || z(e, n.errorClass)) && (M && (C = e.offsetWidth),
                            e._lazyRace = !0, $++, n.rC && (C = n.rC(e, C) || C), V.add(function () {
                                (o = h(e, "lazybeforeunveil")).defaultPrevented || (E && (M ? (N.updateElem(e,
                                        !0, C), g(e, n.autosizesClass)) : e.setAttribute("sizes", E)), a =
                                    e[s](n.srcsetAttr), t = e[s](n.srcAttr), A && (i = e.parentNode, r = i &&
                                        d.test(i.nodeName || "")), u = o.detail.firesLoad || "src" in e &&
                                    (a || t || r), o = {
                                        target: e
                                    }, u && (y(e, I, !0), clearTimeout(b), b = l(I, 2500), g(e, n.loadingClass),
                                        y(e, K, !0)), r && m.call(i.getElementsByTagName("source"), U), a ?
                                    e.setAttribute("srcset", a) : t && !r && (k.test(e.nodeName) ? Q(e, t) :
                                        e.src = t), (a || r) && p(e, {
                                        src: t
                                    })), c(function () {
                                    e._lazyRace && delete e._lazyRace, v(e, n.lazyClass), u && !e.complete ||
                                        (u ? I(o) : $--, J(o))
                                })
                            }))
                    },
                    Y = function () {
                        if (!f) {
                            if (Date.now() - M < 999) return void l(Y, 999);
                            var e, a = function () {
                                n.loadMode = 3, G()
                            };
                            f = !0, n.loadMode = 3, t.hidden ? (q(), V.run()) : G(), o("scroll", function () {
                                3 == n.loadMode && (n.loadMode = 2), clearTimeout(e), e = l(a, 99)
                            }, !0)
                        }
                    };
                return {
                    _: function () {
                        M = Date.now(), i = t.getElementsByClassName(n.lazyClass), u = t.getElementsByClassName(
                            n.lazyClass + " " + n.preloadClass), D = n.hFac, o("scroll", G, !0), o("resize",
                            G, !0), e.MutationObserver ? new MutationObserver(G).observe(a, {
                            childList: !0,
                            subtree: !0,
                            attributes: !0
                        }) : (a[r]("DOMNodeInserted", G, !0), a[r]("DOMAttrModified", G, !0), setInterval(G,
                            999)), o("hashchange", G, !0), ["focus", "mouseover", "click", "load",
                            "transitionend", "animationend", "webkitAnimationEnd"].forEach(function (e) {
                            t[r](e, G, !0)
                        }), /d$|^c/.test(t.readyState) ? Y() : (o("load", Y), t[r]("DOMContentLoaded", G),
                            l(Y, 2e4)), G(i.length > 0)
                    },
                    checkElems: G,
                    unveil: X
                }
            }(),
            N = function () {
                var e, a = function (e, t, n) {
                        var a, i, r, s, o = e.parentNode;
                        if (o && (n = b(e, o, n), s = h(e, "lazybeforesizes", {
                                width: n,
                                dataAttr: !!t
                            }), !s.defaultPrevented && (n = s.detail.width, n && n !== e._lazysizesWidth))) {
                            if (e._lazysizesWidth = n, n += "px", e.setAttribute("sizes", n), d.test(o.nodeName ||
                                    ""))
                                for (a = o.getElementsByTagName("source"), i = 0, r = a.length; r > i; i++) a[i].setAttribute(
                                    "sizes", n);
                            s.detail.dataAttr || p(e, s.detail)
                        }
                    },
                    i = function () {
                        var t, n = e.length;
                        if (n)
                            for (t = 0; n > t; t++) a(e[t])
                    },
                    r = A(i);
                return {
                    _: function () {
                        e = t.getElementsByClassName(n.autosizesClass), o("resize", r)
                    },
                    checkElems: r,
                    updateElem: a
                }
            }(),
            M = function () {
                M.i || (M.i = !0, N._(), E._())
            };
        return function () {
            var t, a = {
                lazyClass: "lazyload",
                loadedClass: "lazyloaded",
                loadingClass: "lazyloading",
                preloadClass: "lazypreload",
                errorClass: "lazyerror",
                autosizesClass: "lazyautosizes",
                srcAttr: "data-src",
                srcsetAttr: "data-srcset",
                sizesAttr: "data-sizes",
                minSize: 40,
                customMedia: {},
                init: !0,
                expFactor: 1.7,
                hFac: .8,
                loadMode: 2
            };
            n = e.lazySizesConfig || e.lazysizesConfig || {};
            for (t in a) t in n || (n[t] = a[t]);
            e.lazySizesConfig = n, l(function () {
                n.init && M()
            })
        }(), {
            cfg: n,
            autoSizer: N,
            loader: E,
            init: M,
            uP: p,
            aC: g,
            rC: v,
            hC: z,
            fire: h,
            gW: b
        }
    }
});